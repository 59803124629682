function CustomMarker(latlng, map, args) {
  this.latlng = latlng;
  this.args = args;
  this.setMap(map);
}

function locObj() {
  this.lat;
  this.lng;
}

function initMap() {
  window.CustomMarker.prototype = new google.maps.OverlayView();
  hasInitialized = true;
  window.CustomMarker.prototype.draw = function () {
    var self = this;

    var div = this.div;

    if (!div) {
      div = this.div = document.createElement("div");

      div.className = "marker";

      div.style.position = "absolute";
      div.style.cursor = "pointer";
      div.style.width = "3em";
      div.style.height = "3em";
      div.style.borderRadius = "25%";
      div.style.background = "white";
      div.style.textAlign = "center";

      if (typeof self.args.marker_id !== "undefined") {
        div.dataset.marker_id = self.args.marker_id;
      }

      if (typeof self.args.icon_class !== "undefined") {
        div.innerHTML = "<i class='" + self.args.icon_class + " fgTeal' style='font-size: 3em;'></i>";
      }

      if (typeof self.args.moreServices !== "undefined" && self.args.moreServices == true) {
        var newDiv = document.createElement("div");
        newDiv.style.position = "absolute";
        newDiv.style.cursor = "pointer";
        newDiv.style.width = "1em";
        newDiv.style.height = "1em";
        newDiv.style.borderRadius = "25%";
        newDiv.style.background = "white";
        newDiv.style.textAlign = "right";
        newDiv.style.right = "0";
        newDiv.style.bottom = "-1px";

        newDiv.innerHTML = "<i class='fas fa-plus fgGold' style='font-size: 1em;'></i>";
        div.appendChild(newDiv);
      }

      div.addEventListener("click", function (event) {
        google.maps.event.trigger(self, "click");
      });

      var panes = this.getPanes();
      panes.overlayImage.appendChild(div);
    }

    var point = this.getProjection().fromLatLngToDivPixel(this.latlng);

    if (point) {
      div.style.left = point.x - 10 + "px";
      div.style.top = point.y - 20 + "px";
    }
  };

  window.CustomMarker.prototype.remove = function () {
    if (this.div) {
      this.div.parentNode.removeChild(this.div);
      this.div = null;
    }
  };

  window.CustomMarker.prototype.hide = function () {
    if (this.div) {
      this.div.style.visibility = "hidden";
    }
  };

  window.CustomMarker.prototype.show = function () {
    if (this.div) {
      this.div.style.visibility = "inherit";
    }
  };

  window.CustomMarker.prototype.getPosition = function () {
    return this.latlng;
  };
}
