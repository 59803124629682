var LivAnimator = function(language, livClass) {
  this.e = null;
  this.p = null;
  this.eStyle = "";
  this.liv = null;
  this.periodicWaveHandler = null;
  this.blockAnimations = false;
  this.language = language;
  this.livIdentifier = livClass;

  this.talk = function(maxTimeInSeconds, livClass) {
    if (window.isIE == true) return;
    var ref = this;
    var maxTimeInMilliseconds = maxTimeInSeconds * 1000;

    if (maxTimeInSeconds >= 3) {
      // Remove 2 seconds to begin talking
      // maxTimeInMilliseconds = 700;
      // maxTimeInSeconds -= 0.7;
    } else {
      if (maxTimeInSeconds <= 0) return;
      // maxTimeInSeconds = 0;
    }

    // Start Talking
    if (this.liv != null) {
      this.e.style.display = "none";
    } else {
      this.livClassName = livClass;
      this.e = document.getElementsByClassName(livClass)[0];
      this.p = this.e.parentNode;
      this.eStyle = this.e.style.display;
      this.e.style.display = "none";

      this.liv = document.createElement("div");
      this.liv.className = this.livClassName + " LivTalkBase";
      this.p.insertBefore(this.liv, this.e);

      this.livMouth = document.createElement("div");
      this.livMouth.className = "mouth";
      this.liv.appendChild(this.livMouth);
    }

    var O = {
      maxTimeInSeconds: maxTimeInMilliseconds,
      maxTimeInSeconds: maxTimeInSeconds,
      ref: ref,
      livClass: livClass,
      e: this.e,
      p: this.p,
      eStyle: this.eStyle,
      liv: this.liv,
      livClassName: livClass
    };

    ref.LivTalk(O);
  };

  this.LivTalk = function(O) {
    if (window.isIE == true) return;
    // // console.log(O);
    O.liv.className = O.livClassName + " LivTalkBase open";
    var rando = Math.floor(Math.random() * (400 - 200 + 1)) + 200;
    // // console.log(rando);
    O.maxTimeInMilliseconds = rando;
    O.maxTimeInSeconds -= rando * 0.001;
    setTimeout(
      function(O) {
        O.ref.LivSilence(O);
      },
      O.maxTimeInMilliseconds,
      O
    );
  };

  this.LivSilence = function(O) {
    if (window.isIE == true) return;
    // // console.log(O);
    O.liv.className = O.livClassName + " LivTalkBase close";
    if (O.maxTimeInSeconds <= 0) return;
    setTimeout(function() {
      O.ref.talk(O.maxTimeInSeconds, O.livClass);
    }, O.maxTimeInMilliseconds);
  };

  this.periodicWave = function(maxTime) {
    // Disable by uncommenting "return" below
    if (window.isIE == true) return;

    var ref = this;
    // console.log("canceling periodic wave");
    // console.log(ref.blockAnimations);
    ref.cancelPeriodicWave();
    maxTime = maxTime ? maxtime : 100000;
    // maxTime = maxTime ? maxtime : 10000;

    var timing = Math.random() * maxTime;

    // var timing = 3000;

    this.periodicWaveHandler = setTimeout(
      function() {
        if (ref.blockAnimations == false) {
          ref.wavePose();
          ref.periodicWave();
        }
      },
      timing,
      ref
    );
  };

  this.cancelPeriodicWave = function() {
    if (this.periodicWaveHandler != null) clearTimeout(this.periodicWaveHandler);
  };

  this.standardPose = function(isSmall) {
    // This should place the global liv into a resting position.
    // // console.log("test");
    var livs = document.getElementsByClassName(this.livIdentifier);
    var liv = livs[0];
    if (!liv) return;
    // console.log(liv);
    // var language = this.retrieveData("Language");
    liv.innerHTML = "";
    livClassString = "liv livBase livFadeIn " + this.language + " " + this.livIdentifier;
    if (isSmall) {
      livClassString += " smaller";
    }
    liv.className = livClassString;
  };

  this.transitionPoseOut = function(callback, callback2) {
    var livs = document.getElementsByClassName(this.livIdentifier);
    var liv = livs[0];
    // console.log(liv);
    // var language = this.retrieveData("Language");
    liv.innerHTML = "";
    liv.className = "liv livFadeOut " + this.language + " " + this.livIdentifier;

    var ref = this;

    setTimeout(
      function() {
        if (!!callback && !!callback2) callback(callback2);
        if (!!callback && !callback2) callback();
      },
      600,
      ref
    );
  };

  this.transitionPoseIn = function(callback, callback2) {
    var livs = document.getElementsByClassName(this.livIdentifier);
    var liv = livs[0];
    // console.log(liv);
    // var language = this.retrieveData("Language");
    liv.innerHTML = "";
    liv.className = "liv livFadeIn " + this.language + " " + this.livIdentifier;

    var ref = this;

    setTimeout(
      function() {
        if (!!callback && !!callback2) callback(callback2);
        if (!!callback && !callback2) callback();
      },
      600,
      ref
    );
  };

  this.wavePose = function() {
    var ref = this;

    if (ref.blockAnimations == true) {
      return;
    }
    // This should place the global liv into a resting position.
    // // console.log("test");
    var livs = document.getElementsByClassName(this.livIdentifier);
    var liv = livs[0];
    // console.log(liv);
    if (!liv) return;
    // var language = this.retrieveData("Language");
    liv.className = "liv livWaveBase " + this.language + " " + this.livIdentifier;

    liv.innerHTML = "";

    var arm = document.createElement("div");
    arm.className = "livArm " + language;
    liv.appendChild(arm);

    var forearm = document.createElement("div");
    forearm.className = "livForearm " + language;
    liv.appendChild(forearm);

    var hand = document.createElement("div");
    hand.className = "livHand wave " + language;
    liv.appendChild(hand);

    if (ref.blockAnimations == true) {
      return;
    }

    setTimeout(
      function() {
        if (ref.blockAnimations == true) {
          return;
        }
        ref.standardPose();
        // ref.transitionPoseOut(ref.transitionPoseIn, ref.standardPose);
      },
      2000,
      ref
    );
  };

  this.handUp = function() {
    // This should place the global liv into a resting position.
    // // console.log("test");

    var ref = this;

    if (ref.blockAnimations == true) {
      return;
    }

    var livs = document.getElementsByClassName(this.livIdentifier);
    var liv = livs[0];
    // console.log(liv);
    // var language = this.retrieveData("Language");
    liv.className = "liv livBase " + this.language + " " + this.livIdentifier;

    liv.innerHTML = "";

    var arm = document.createElement("div");
    arm.className = "livArm " + language;
    liv.appendChild(arm);

    var forearm = document.createElement("div");
    forearm.className = "livForearm " + language;
    liv.appendChild(forearm);

    var hand = document.createElement("div");
    hand.className = "livHand " + language;
    liv.appendChild(hand);
  };

  this.hideLivCage = function() {
    var e = document.getElementsByClassName("epLivCage")[0];
    var parent = e.parentNode;
    var oldDisplayValue = e.style.display;
    e.style.display = "none";

    var div = document.createElement("div");
    div.className = "LivCageBase";
    parent.appendChild(div);
  };

  this.hide = function() {
    var livs = document.getElementsByClassName(this.livIdentifier);
    var liv = livs[0];
    liv.className = "liv livInvisible " + this.livIdentifier;
  };

  //   this.retrieveData = function(subkey) {
  //     var data = localStorage.retrieveData("Language");
  //     console.log(data);
  //     var parsedData;
  //     try {
  //       parsedData = JSON.parse(data);
  //     } catch (ex) {
  //       parsedData = null;
  //     }
  //     return parsedData;
  //   };
  this.cancelPeriodicWave();
  this.periodicWave();
};
